import {Sales, SalesItems, SalesItemsPriceChanges, SalesPriceChanges} from "tilby-models";

export interface ShopCartSales extends Sales{
    original_updated_at?:Sales["lastupdate_at"];
}
export interface ShopCartSalesEdited extends ShopCartSales{
    $priceChangesOrderedByIndex?: (SalesPriceChanges&{$partial?:number})[];
    $salePriceChangesAmount?: number;
    sale_items?:SaleItemWithMenu[];
}

export interface ShopCartSalesItems extends SalesItems{
   $partialAmount?:number;
   $priceChangesOrderedByIndex?:(SalesItemsPriceChanges&{$partial?:number})[];
   $unavailable?: boolean;
}

export interface SaleItemWithMenu extends ShopCartSalesItems{
    is_group_item?: boolean;
    split_group_components?: boolean;
    sale_item_parent_uuid?: string;
    $menuIndex?: number;
}

export type SaleFixDate = {
    [prop in keyof Sales]: Sales[prop] extends Date? string: Sales[prop]
}

export const LiberaTavolo= "ORDER.ACTION.GENERIC_LIST_DIALOG.FREE_TABLE";

export class SaleDefault implements Sales{
    currency="EUR";
    name= "Comanda";
    open_at= new Date();
    seller_id= 0;
    seller_name= "";
    status: Sales.StatusEnum = 'open';
    covers?:number = 0;
    table_id?:number;

}

export interface VariationDialogData {
    salesItem: SaleItemWithMenu;
    isNew: boolean;
}


export type PriceChangePlus=SalesPriceChanges & { $partial?: number };
export type RemovePriceChangeFromSaleItem = {
    saleItem: SaleItemWithMenu,
    priceChange:SalesItemsPriceChanges
}

export type GenericListItem = {
    name: string,
    nameValue?: number | string,
    finalAmount?: number,
    isSelected?: boolean
};

export type ExitName = `DIALOGS.EXIT_DIALOG.EXIT` | 'DIALOGS.EXIT_DIALOG.NO_EXIT_LABEL';
type ExitReady = undefined;
type ExitStatus = 'sent' | 'sending' | ExitReady;

export class Exit {
    isSelected?: boolean;
    name: ExitName;
    nameValue?: number;
    status?: ExitStatus;

    constructor(nameValue?: number,isSelected?: boolean, status?: ExitStatus) {
        this.name = nameValue ? `DIALOGS.EXIT_DIALOG.EXIT` : 'DIALOGS.EXIT_DIALOG.NO_EXIT_LABEL';
        this.nameValue = nameValue;
        this.isSelected = isSelected;
        this.status = status;
    }
};

//TypeGuard
export function isSale(pet: Sales | {name:string}): pet is Sales {
    return (pet as Sales).sale_items !== undefined;
}
