export type Color =
    'green'         // table.state = 'booking'
    | 'grey'        // table.state = 'no booking'

export enum ICON_TO_SVG_TABLES{
    rect1x1='01_Quadrato_4Posti.svg#square',
    circle4='02_Circolare_4Posti.svg#circle4',
    circle8='03_Circolare_8Posti.svg#circle8',
    oval_hor='04_Ovale_6Posti.svg#oval_hor',
    oval_vert='04_OvaleVerticale_6Posti.svg#oval_vert',
    rect2x1='05_Rettangolare_6Posti.svg#rect_hor_6',
    rect3x1='ic_table_rect3x1.svg#Livello_1',
    rect5x1='06_Rettangolare_12Posti.svg#rect_hor_12',
    rect1x2='05_RettangolareVerticale_6Posti.svg#rect_vert_6',
    rect1x3='ic_table_rect1x3.svg#Livello_1',
    rect1x5='06_RettangolareVerticale_12Posti.svg#rect_vert_12',
    chair_dx='08_Sedia_destra.svg#chair_dx',
    chair_sx='08_Sedia_sinistra.svg#chair_sx',
    chair1x1='08_Sedia_sopra.svg#chair_top',
    chair_bottom='08_Sedia_sotto.svg#chair_bottom',
    counter_top='07_Bancone_sopra.svg#counter_top',
    counter_bottom='07_Bancone_sotto.svg#counter_bottom',
    counter_dx='07_BanconeVerticale_destra.svg#counter_dx',
    counter_sx='07_BanconeVerticale_sinistra.svg#counter_sx',
}
export enum ICON_TO_SVG_ELEMENTS{
    ph_wall_v='ph_wall_v',
    ph_wall_h='ph_wall_h',
    ph_plant1='ph_plant1',
    ph_plant2='ph_plant2',
    ph_armchair_dx='01_Poltrona_destra',
    ph_armchair_sx='01_Poltrona_sinistra',
    ph_armchair_top='01_Poltrona_sopra',
    ph_armchair_bottom='01_Poltrona_sotto',
    ph_sofa_dx='02_Divano_destra',
    ph_sofa_sx='02_Divano_sinistra',
    ph_sofa_top='02_Divano_sopra',
    ph_sofa_bottom='02_Divano_sotto',
    ph_plant='03_Pianta',
    ph_cashreg_counter='04_BancoCassa',
    ph_cashreg_only='04_SoloCassa',
}

export const STATIC_ELEMENT_PREFIX = 'ph_';
